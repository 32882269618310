(function (window, undefined) {
  window.acpv.ClipboardCopy = function () {
    let trigger = document.createElement('div');
    trigger.hidden = true;
    document.body.appendChild(trigger);
    let clipboard = new ClipboardJS(trigger);
    return {
      copy: function (textFunc) {
        clipboard.text = textFunc;
        let promise = new Promise(function (resolve, reject) {
          clipboard.on('success', function () {
            clipboard.off('success');
            clipboard.off('error');
            resolve();
          });
          clipboard.on('error', function () {
            clipboard.off('success');
            clipboard.off('error');
            reject();
          });
        });
        trigger.click();
        return promise;
      },
      destroy: function () {
        clipboard.destroy();
      }
    }
  }
})(window)
